// user 選單 uid 暱稱
import useCopy from '@/hook/useCopy';

const UserMenuUid = ({ user }) => {
    const copy = useCopy();

    return (
        <>
            {/* 暱稱 */}
            <p className="text-bd1b text-black">{user?.nickname}</p>

            {/* uid */}
            <p
                className="cp text-bd3n text-black9 mt-[6px] opacity-[.6]"
                onClick={() => copy('@' + user?.uid, 'COPY_UID')}
            >
                @{user?.uid}
            </p>
        </>
    );
};

export default UserMenuUid;
