import apiClient from './apiClient';

class FlashClient {
    // 取得快訊列表
    getFlashList = (locale, pageIndex = 0, pageSize = 5, appPush = 0) =>
        apiClient.get(
            `/flash/list?pageIndex=${pageIndex}&pageSize=${pageSize}&appPush=${appPush}`,
            {
                headers: {
                    'X-LANG': 'zh-' + locale,
                },
            }
        );
}

const flashClient = new FlashClient();

export default flashClient;
