// 首頁日曆輪播元件 (宏觀數據、代幣解鎖、自訂)
import dayjs from 'dayjs';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { sify } from 'chinese-conv'; // 轉簡體用

const Inner = ({ t, calendar, locale }) => {
    const pcReleaseTime = dayjs(calendar?.releaseTime).format('YYYY.MM.DD HH:mm');
    const mbReleaseTIme = dayjs(calendar?.releaseTime).format('YYYY.MM.DD');

    return (
        <>
            <span className="relative z-[2] hidden ulg:inline-block rounded-[50%] w-[5px] h-[5px] bg-[#91ffff]" />
            <div
                className="flex items-center relative trans group-hover:translate-x-[10px] flex-grow [&>div]:flex [&>div]:items-center [&>div]:gap-x-[30px] 
                [&>div>p]:text-bd3n [&>div_span]:text-btn1 [&>div>p]:flex [&>div>p]:items-center [&>div>p]:gap-x-[7px]
                [&>div_span]:relative [&>div_span]:bottom-[1px] [&>div]:flex-grow line-clamp-1"
            >
                {/* 事件標題、代幣名稱 */}

                <p className="text-btn1">
                    {calendar?.type === 'unlocked'
                        ? t('UNLOCK')
                        : locale === 'tw'
                        ? calendar?.title
                        : sify(calendar?.title)}
                </p>

                {/* 分隔線 */}
                {calendar?.type !== 'customized' && (
                    <span
                        className={`w-[1px] h-[10px] border-l-1 border-[#c7c7c7] mx-[20px] ${
                            calendar?.type === 'marco' && !calendar?.period ? 'ulg:hidden' : ''
                        }`}
                    />
                )}

                {/* 宏觀數據 */}
                {calendar?.type === 'marco' && (
                    <div className="[&>p]:ulg:!hidden">
                        {calendar?.period && <span>{calendar?.period}</span>}
                        {/* 公布值 */}
                        <p>
                            {t('ANNOUNCED_VALUE')}
                            <span>{calendar?.announcedValue || 'TBA'}</span>
                        </p>
                        {/* 預測值 */}
                        <p>
                            {t('FORECAST_VALUE')}
                            <span>{calendar?.forecastValue || 'TBA'}</span>
                        </p>
                        {/* 前值 */}
                        <p>
                            {t('PREVIOUS_VALUE')}
                            <span>{calendar?.previousValue}</span>
                        </p>
                    </div>
                )}

                {/* 代幣解鎖 */}
                {calendar?.type === 'unlocked' && (
                    <div className="[&>p]:ulg:!hidden">
                        {/* 代幣名稱 */}
                        <span className="btn3b">
                            {locale === 'tw' ? calendar?.title : sify(calendar?.title)}
                        </span>
                        {/* 解鎖量 */}
                        <p>
                            {t('UNLOCK_AMOUNT')}
                            <span>
                                {locale === 'tw'
                                    ? calendar?.unlockedAmount
                                    : sify(calendar?.unlockedAmount)}
                            </span>
                        </p>
                        {/* 流通供應量 */}
                        <p>
                            {t('SUPPLY')}
                            <span>{calendar?.supply}</span>
                        </p>
                        {/* 價值 */}
                        <p>
                            {t('VALUE')}
                            <span>
                                {locale === 'tw'
                                    ? calendar?.unlockedValue
                                    : sify(calendar?.unlockedValue)}
                            </span>
                        </p>
                    </div>
                )}
            </div>

            {/* 事件時間 */}
            <p
                className="text-btn2 flex items-center gap-x-[8px] trans group-hover:translate-x-[-85px] ulb:hidden ulg:!flex
            [@media(max-width:388px)]:!hidden"
            >
                <Image
                    className="object-contain"
                    width="16"
                    height="16"
                    src="/images/icon/icon_clock.svg"
                    alt="icon"
                />
                <span className="ulg:hidden">{pcReleaseTime}</span>
                <span className="hidden ulg:inline-flex">{mbReleaseTIme}</span>
            </p>

            <p className="absolute trans opacity-0 text-btn1 z-[2] right-[23px] group-hover:opacity-[1]">
                了解更多 +
            </p>
        </>
    );
};

const IndexEventCalendarComponent = ({ calendar, locale }) => {
    const { t } = useTranslation();

    // 有連結
    if (calendar?.link) {
        return (
            <a
                href={calendar?.link}
                target="_blank"
                rel="noreferrer noopener"
                className="text-[#fff] px-[30px] py-[6px] between-center gap-x-[15px] h-full group w-full ulg:gap-x-[12px] usm:px-[15px]"
            >
                <Inner t={t} calendar={calendar} locale={locale} />
            </a>
        );
    }

    // 無連結
    return (
        <div className="text-[#fff] px-[30px] py-[6px] between-center gap-x-[15px] h-full cursor-default w-full ulg:gap-x-[12px] usm:px-[15px]">
            <Inner t={t} calendar={calendar} locale={locale} />
        </div>
    );
};

export default IndexEventCalendarComponent;
