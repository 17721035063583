// 新聞文章 進度條
'use client';
import { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'next/navigation';
import { popState, setNewsProgress, setCatalog } from '@/slices/popSlice';
import { usePathname } from 'next/navigation';

import useCopy from '@/hook/useCopy';
import SocialShareComponent from './SocialShareComponent'; // 社群分享

import Image from 'next/image';
import { CRYPTO_CITY_OFFICIAL_URL } from '@/common/constant';

const NewsProgressBar = ({ locale }) => {
    const { academySlug, newsSlug, projectSlug } = useParams();
    const dispatch = useDispatch();
    const copy = useCopy();
    const pathname = usePathname();

    const { showNewsProgress, newsBar, showCatalog } = useSelector(popState);

    const [y, setY] = useState(null);
    const [showShareButton, setShowShareButton] = useState(false); // 顯示社群分享按鈕

    // 處理Scroll
    const handleScroll = useCallback(() => {
        const scrollY = window.scrollY;

        if (scrollY > 150 && pathname.includes('news/')) {
            if (y > scrollY) {
                dispatch(setNewsProgress(false));
                if (window.innerWidth > 1023) setShowShareButton(false);
            } else if (y < scrollY) {
                dispatch(setNewsProgress(true));
            }
        }
        setY(scrollY);
    }, [y]);

    const handleShowShareButton = useCallback((e) => {
        const targetClass = e.target?.className?.toString() || '';
        if (!targetClass.includes('share-button') && !targetClass?.includes('open-button'))
            setShowShareButton(false);
        if (targetClass?.includes('open-button')) setShowShareButton((prev) => !prev);
    }, []);

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        dispatch(setNewsProgress(false));
    }, [pathname]);

    useEffect(() => {
        window.addEventListener('click', (e) => handleShowShareButton(e));

        return () => window.removeEventListener('click', (e) => handleShowShareButton(e));
    }, []);

    // 新聞、學院文章頁
    if (newsSlug || academySlug)
        return (
            <div
                className={`${
                    academySlug ? 'hidden' : ''
                } trans fixed w-screen ulg:block left-0 z-[12] bg-white border-b-1 border-black2
                px-[30px] flex items-center ulg:bottom-0 ulg:top-[unset] ulg:bg-[rgba(255,255,255,.8)] ulg:backdrop-blur-[10px]
                dark:ulg:bg-[rgba(14,14,14,.56)] ulg:border-t-1 ulg:border-b-0 ulg:border-[#e4e4e4] usm:px-[20px] uxs:!px-[15px]
                ${showNewsProgress ? 'top-0' : 'top-[-60px]'}`}
            >
                {/* 進度條 */}
                <div
                    style={{ width: newsBar?.progress + '%' }}
                    className={`h-[2px] absolute z-[1] left-0 bottom-[0px] bg-black ulg:hidden`}
                />

                <div className="h-[54px] max-w-[1440px] mx-auto w-full between-center gap-x-[30px] relative usx:gap-x-[15px] uxs:!gap-x-[2px]">
                    {/* 新聞標題 */}
                    <p className="text-bd1b text-black line-clamp-1 ulg:hidden">{newsBar?.title}</p>

                    {/* 手機版目錄按鈕 */}
                    <button
                        className="hidden ulg:block text-bd3n text-[#fff] bg-[#393939] rounded-[40px] py-[6px] px-[30px] uxs:px-[15px] flex-shrink-0"
                        onClick={() => dispatch(setCatalog(!showCatalog))}
                    >
                        {locale === 'tw' ? '目錄' : '目录'}
                    </button>

                    {/* 社群分享 */}
                    <div
                        className="relative flex items-center justify-end gap-x-[4px] text-btn2 text-black9 [&>*]:flex [&>*]:items-center [&>*]:gap-x-[8px]
                    [&>span]:w-[1px] [&>span]:h-[20px] [&>span]:border-l-1 [&>span]:border-dashed [&>span]:border-black3 flex-shrink-0 usx:[&>*]:gap-x-[3px]
                    dark:[&_img]:brightness-[3.5]"
                    >
                        <a
                            href={CRYPTO_CITY_OFFICIAL_URL.NEW_TELEGRAM}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="px-[10px] py-[5px] usx:px-[9px]"
                        >
                            <Image
                                width={20}
                                height={20}
                                src="/images/icon/icon_link_tg.svg"
                                alt="telegram"
                            />
                            TG群
                        </a>

                        <span />

                        <a
                            href={CRYPTO_CITY_OFFICIAL_URL.LINE}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="px-[10px] py-[5px] usx:px-[9px]"
                        >
                            <Image
                                width={20}
                                height={20}
                                src="/images/icon/icon_link_line.svg"
                                alt="line"
                            />
                            Line群
                        </a>

                        <span />

                        <button className="px-[10px] w-[74px] usx:w-[67px] py-[5px] usx:px-[9px] open-button relative justify-end">
                            <Image
                                className="absolute left-[10px] uxs:left-[9px] w-[20px] h-[20px] z-[-1]"
                                width={20}
                                height={20}
                                src="/images/icon/icon_link_share.svg"
                                alt="share"
                            />
                            分享
                        </button>
                    </div>

                    {showShareButton && <SocialShareComponent copy={copy} page="news" />}
                </div>
            </div>
        );

    // 專題內容頁
    if (projectSlug)
        return (
            <div
                className="hidden ulg:block z-[5] fixed bottom-0 w-screen left-0 backdrop-blur-[10px] py-[12px] px-[5px] border-t-1 border-[#e4e4e4] bg-[#fff]
            bg-opacity-[.85] text-bd1b text-[#393939] dark:ulg:bg-[rgba(14,14,14,.56)] dark:bg-opacity-[unset] dark:border-[rgb(75,75,75)]"
            >
                <SocialShareComponent copy={copy} page="project" />
            </div>
        );

    return <></>;
};

export default NewsProgressBar;
