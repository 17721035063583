// 最新新聞元件
import { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import dayjs from 'dayjs';
import Link from 'next/link';
import Image from 'next/image';

const LatestNewsComponent = ({ t, news }) => {
    if (!news) return null;

    const router = useRouter();

    // 新聞發佈時間
    const [displayTime, setDisplayTime] = useState(null);

    // 新聞類型
    const category = t(news.category.toUpperCase());

    // 點擊分類跳轉
    const handleCategoryClick = (e) => {
        e.preventDefault();
        router.push(`/news?category=${news.category}`);
    };

    useEffect(() => {
        setDisplayTime(dayjs(news?.displayTime).format('YYYY.MM.DD'));
    }, []);

    return (
        <Link
            href={`/news/${news.slug}`}
            className="news-list-item flex gap-x-[35px] group uxl:gap-x-[15px] umd:!gap-x-[10px] w-full"
        >
            <div className="flex flex-col justify-between gap-y-[20px] flex-grow">
                {/* 新聞標題 */}
                <h2 className="hover-text text-bd2b text-black9 line-clamp-3 leading-[normal] ulg:text-btn3b lighten-text">
                    {news.title}
                </h2>

                {/* 新聞類別、時間 */}
                <p className="text-btn2">
                    <button
                        className="trans text-pale group-hover:text-pale9"
                        onClick={handleCategoryClick}
                    >
                        {category}
                    </button>
                    <span className="text-btn1 text-black2">{` ／ `}</span>
                    {displayTime && <span className="text-black4">{displayTime}</span>}
                </p>
            </div>

            {/* 新聞圖片 */}
            <div className="img-box w-[168px] h-[130px] rounded-[2px] overflow-hidden flex-shrink-0 uxl:w-[119px] uxl:h-[92px] ulg:!w-[124px] ulg:!h-[96px] uxs:hidden">
                <Image
                    className="hover-img"
                    src={news?.image || news?.imageFb || '/images/news/default_related.svg'}
                    alt={news.title}
                    width={168}
                    height={130}
                    sizes="200px"
                />
            </div>
        </Link>
    );
};

export default LatestNewsComponent;
