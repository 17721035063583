// user 選單 - 任務相關按鈕

const UserMenuTaskButtons = ({ onCheckTask, isRewardNotReceived, cmOrigin, t, showTask }) => {
    if (!showTask)
        return (
            <div className="mt-[16px] flex items-center gap-x-[6px] [&>*]:rounded-[4px] [&>*]:text-center [&>*]:py-[7px] [&>*]:w-full [&>*]:text-white [&>*]:text-btn1 [&>*]:trans">
                {/* 任務列表、領取獎勵 */}
                <button
                    onClick={onCheckTask}
                    className={`bg-pale9 hover:bg-black ${
                        isRewardNotReceived?.length ? 'task-notice' : ''
                    }`}
                >
                    {t(isRewardNotReceived?.length ? 'EARN' : 'TASKS')}
                </button>

                {/* 獎勵兌換 => 會員系統 */}
                <a
                    href={cmOrigin + 'exchange'}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="bg-pale hover:bg-black"
                >
                    {t('EXCHANGE')}
                </a>
            </div>
        );

    return <></>;
};

export default UserMenuTaskButtons;
