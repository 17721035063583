// 最新上線新聞 tab
import LatestNewsComponent from '../basic/LatestNewsComponent';
import LatestNewsSkeltonComponent from '../skelton/LatestNewsSkeltonComponent';

const LatestNewsContent = ({ newsList, t, loading }) => {
    return (
        // 新聞列表
        <div
            className="flex justify-between flex-wrap gap-x-[25px] gap-y-[30px] [&>*]:max-w-[488px]
      uxl:[&>*]:w-[calc((100%-26px)/2)] ulg:[&>*]:!w-full ulg:[&>*]:!max-w-[unset] umd:gap-y-[18px]"
        >
            {loading
                ? new Array(8)
                      .fill(0)
                      .map((_, i) => <LatestNewsSkeltonComponent key={i + 'skelton-latest'} />)
                : newsList?.map((news, i) => (
                      <LatestNewsComponent
                          t={t}
                          key={'index-news-list' + news?.id + i}
                          news={news}
                      />
                  ))}
        </div>
    );
};

export default LatestNewsContent;
