import Image from 'next/image';

const UserMenuTaskTitle = ({ t, showExchangeModal }) => {
    return (
        // 點擊顯示說明彈窗
        <p
            className="flex items-center gap-x-[10px] text-bd1b text-black cp group"
            onClick={showExchangeModal}
        >
            <span>{t('TASK_TITLE')}</span>
            <Image
                className="opacity-[.3] trans group-hover:opacity-[1]"
                width={14}
                height={14}
                src="/images/icon/icon_question.svg"
                alt="icon"
            />
        </p>
    );
};

export default UserMenuTaskTitle;
