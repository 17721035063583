// user 選單

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userState, userLogout, userMutate } from '@/slices/userSlice';
import { useRouter } from 'next/navigation';
import { clearNotification } from '@/slices/notificationSlice';
import { originURL } from '@/slices/originURLSlice';
import { setExchangeModal } from '@/slices/popSlice';
import authClient from '@/common/apiClient/authClient';
import useComma from '@/hook/useComma';

import UserMenuLink from './UserMenuLink'; // 使用者選單連結
import ExchangeTasks from './ExchangeTasks'; // 任務列表
import exchangeClient from '@/common/apiClient/exchangeClient';
import UserMenuTaskButtons from './UserMenuTaskButtons';
import UserEnergy from './UserEnergy';
import UserMenuUid from './UserMenuUid';
import UserMenuTaskTitle from './UserMenuTaskTitle';

const UserMenuModal = ({ showUserMenuModal, dispatch, t, setUserMenuModal }) => {
    const { cmOrigin } = useSelector(originURL);
    const { user } = useSelector(userState);
    const router = useRouter();
    const numberComma = useComma();

    // 判斷有沒有尚未領取得獎勵
    const isRewardNotReceived =
        user?.tasks?.taskState?.filter((task) => task?.isFinish && !task?.isReceived) || [];

    // 顯示任務列表
    const [showTask, setShowTask] = useState(false);

    // 登出
    const onLogout = async () => {
        try {
            await authClient.logout();
            dispatch(setUserMenuModal(false));
            dispatch(userLogout());
            dispatch(clearNotification());
            router.push('/');
        } catch (e) {
            console.error(e);
        }
    };

    // 點擊任務列表
    const onCheckTask = async () => {
        try {
            // 切換選單
            setShowTask(true);

            if (!isRewardNotReceived) return;

            // 如果有尚未領取的獎勵再執行 驗證任務
            await exchangeClient.verifyTasks();
            const newsUserState = await exchangeClient.getUserState(); // user 任務資訊

            if (newsUserState) dispatch(userMutate(newsUserState));
        } catch (e) {
            console.error(e);
        }
    };

    // 顯示說明彈窗
    const showExchangeModal = () => dispatch(setExchangeModal(true));

    // 取得最新 user 能量資訊
    const getNewUserEnergyInfo = async () => {
        try {
            const result = await exchangeClient.getUserState();
            if (result) dispatch(userMutate(result));
        } catch (e) {
            console.error(e);
        }
    };

    // 選單關閉 切回列表
    useEffect(() => {
        if (!showUserMenuModal && showTask) setTimeout(() => setShowTask(false), 400);
    }, [showUserMenuModal]);

    useEffect(() => {
        if (user?.uid) {
            if (showUserMenuModal || !showTask) {
                getNewUserEnergyInfo();
            }
        }
    }, [showUserMenuModal, showTask]);

    return (
        <div
            className={`fixed w-screen h-screen top-0 left-0 trans flex justify-end moving-element
            ${showUserMenuModal ? 'z-[9] ulg:z-[100]' : 'z-[-1] opacity-0'}`}
        >
            {/* 關閉用 */}
            <div
                className="absolute w-screen h-screen top-0 left-0 z-[0] moving-element"
                onClick={() => {
                    dispatch(setUserMenuModal(false));
                }}
            />

            {/* 使用者選單 */}
            <div
                className={`trans relative z-[1] pb-[40px] max-h-screen noScrollY w-[250px] bg-[#ffffff] dark:bg-[#1E1E1E] border-l-1 border-black2 
                px-[22px] pt-[82px] backdrop-blur-[10px] bg-opacity-[.85] ulg:pt-[27px]
                ${!showUserMenuModal ? 'translate-x-[255px]' : 'translate-x-0'} moving-element`}
            >
                {showTask ? (
                    // 能量任務標題
                    <UserMenuTaskTitle t={t} showExchangeModal={showExchangeModal} />
                ) : (
                    // user uid + nickname
                    <UserMenuUid user={user} />
                )}

                <div className="my-[20px] border-1 border-dashed border-black3  border-l-0 border-r-0 py-[20px]">
                    {/* user 任務能量 */}
                    <UserEnergy
                        t={t}
                        tasks={user?.tasks}
                        cmOrigin={cmOrigin}
                        showExchangeModal={showExchangeModal}
                        currentEnergy={numberComma(user?.currentEnergy)}
                    />

                    {/* 列表、兌換按鈕 */}
                    <UserMenuTaskButtons
                        t={t}
                        onCheckTask={onCheckTask}
                        isRewardNotReceived={isRewardNotReceived}
                        cmOrigin={cmOrigin}
                        showTask={showTask}
                    />
                </div>

                {showTask ? (
                    <>
                        {/*  任務列表 */}
                        <ExchangeTasks tasks={user?.tasks?.taskState} t={t} />

                        {/* 返回 */}
                        <button
                            onClick={() => setShowTask(false)}
                            className="w-full text-center rounded-[4px] trans py-[14px] text-btn1 text-black border-1 border-black9
                        hover:text-white hover:bg-jblack"
                        >
                            返回
                        </button>
                    </>
                ) : (
                    <>
                        {/*  使用者選單連結 */}
                        <UserMenuLink t={t} cmOrigin={cmOrigin} />

                        {/* 登出按鈕 */}
                        <button
                            onClick={onLogout}
                            className="w-full text-center rounded-[4px] trans py-[14px] text-btn1 text-black border-1 border-black9
                        hover:text-white hover:bg-jblack"
                        >
                            {t('LOGOUT')}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default UserMenuModal;
