import moment from 'moment';
import Link from 'next/link';

const FlashSwiperComponent = ({ flash, index }) => {
    const time = moment(flash.publishedTime).fromNow();

    if (flash)
        return (
            <>
                <Link href={`/flash/${flash?.id}`} className="group" prefetch={false}>
                    <span className="hover-text line-clamp-2 !underline-offset-1">
                        {flash?.title}
                    </span>
                    <span suppressHydrationWarning className="text-bd3n text-black4">
                        {time}
                    </span>
                </Link>
                {index < 3 && (
                    <hr className="border-dotted w-full border-black4 border-opacity-[.7]" />
                )}
            </>
        );

    return <></>;
};

export default FlashSwiperComponent;
