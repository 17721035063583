// 幣價顯示 (btc,eth,sol)
'use client';
import { useEffect, useState } from 'react';
import useComma from '@/hook/useComma';
import Image from 'next/image';

const CoinPrice = () => {
    const numberComma = useComma();
    // 幣價
    const [btcPrice, setBtcPrice] = useState(null);
    const [ethPrice, setEthPrice] = useState(null);
    const [solPrice, setSolPrice] = useState(null);

    useEffect(() => {
        const btcSocket = new WebSocket('wss://stream.binance.com:9443/ws/btcusdt@ticker');
        const ethSocket = new WebSocket('wss://stream.binance.com:9443/ws/ethusdt@ticker');
        const solSocket = new WebSocket('wss://stream.binance.com:9443/ws/solusdt@ticker');

        btcSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setBtcPrice({
                price: Number(data.c).toFixed(0),
                priceChangePercent: Number(data.P).toFixed(2),
            });
        };

        ethSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setEthPrice({
                price: Number(data.c).toFixed(0),
                priceChangePercent: Number(data.P).toFixed(2),
            });
        };

        solSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setSolPrice({
                price: Number(data.c).toFixed(0),
                priceChangePercent: Number(data.P).toFixed(2),
            });
        };

        return () => {
            if (btcSocket.readyState === WebSocket.OPEN) btcSocket.close();
            if (ethSocket.readyState === WebSocket.OPEN) ethSocket.close();
            if (solSocket.readyState === WebSocket.OPEN) solSocket.close();
        };
    }, []);

    if (!btcPrice || !ethPrice || !solPrice) return <></>;

    return (
        <div className="flex between-center flex-grow space-x-[33px] no-select relative z-[1] tabular">
            <div className="flex items-center space-x-[10px]">
                <Image src={`/images/icon/icon_btc.svg`} alt="btc" width={16} height={16} />
                <span className="text-btn1b text-black ">BTC</span>
                <span className="text-btn1 text-black ">${numberComma(btcPrice?.price)}</span>
                <span
                    className={`text-btn1b ${
                        btcPrice?.priceChangePercent > 0 ? 'text-[#1ab260]' : 'text-[#D3392F]'
                    }`}
                >
                    {btcPrice?.priceChangePercent > 0 ? '+' : ''}
                    {btcPrice?.priceChangePercent}%
                </span>
            </div>

            <div className="flex items-center space-x-[10px]">
                <Image src={`/images/icon/icon_eth.svg`} alt="eth" width={16} height={16} />
                <span className="text-btn1b text-black ">ETH</span>
                <span className="text-btn1 text-black ">${numberComma(ethPrice?.price)}</span>
                <span
                    className={`text-btn1b ${
                        ethPrice?.priceChangePercent > 0 ? 'text-[#1ab260]' : 'text-[#D3392F]'
                    }`}
                >
                    {ethPrice?.priceChangePercent > 0 ? '+' : ''}
                    {ethPrice?.priceChangePercent}%
                </span>
            </div>

            <div className="flex items-center space-x-[10px]">
                <Image src={`/images/icon/icon_sol.svg`} alt="sol" width={16} height={16} />
                <span className="text-btn1b text-black ">SOL</span>
                <span className="text-btn1 text-black ">${numberComma(solPrice?.price)}</span>
                <span
                    className={`text-btn1b ${
                        solPrice?.priceChangePercent > 0 ? 'text-[#1ab260]' : 'text-[#D3392F]'
                    }`}
                >
                    {solPrice?.priceChangePercent > 0 ? '+' : ''}
                    {solPrice?.priceChangePercent}%
                </span>
            </div>
        </div>
    );
};

export default CoinPrice;
