// 最新專題元件
import dayjs from 'dayjs';
import Link from 'next/link';
import Image from 'next/image';

const LatestProjectComponent = ({ project, i }) => {
    // 更新時間
    const modifiedTime = dayjs(project?.modifiedTime).format('YYYY.MM.DD');

    return (
        <Link
            href={`/project/${project?.slug}`}
            className={`flex w-full group items-start gap-x-[40px] py-[23px] border-dashed border-black2
      ${i === 3 ? 'pb-0 border-0' : 'border-b-1'} ${
                i === 0 ? 'pt-0' : ''
            } uxl:gap-x-[15px] blg:gap-x-[40px] bmd:gap-x-[40px] bsm:gap-x-[40px] usm:!gap-x-[10px]`}
            prefetch={false}
        >
            <div className="text-left flex-grow">
                <h3 className="hover-text text-bd2b text-black leading-[normal] line-clamp-2">
                    {project?.title}
                </h3>
                <p className="mt-[20px] text-btn1 text-black5">最近更新：{modifiedTime}</p>
            </div>

            {/* 專題圖片 */}
            <div
                className="img-box w-[172px] h-[104px] rounded-[4px] overflow-hidden
                uxl:w-[116px] blg:w-[172px] bmd:w-[172px] bsm:w-[172px] usm:!w-[124px] usm:!h-[96px]"
            >
                <Image
                    loading="lazy"
                    className="hover-img"
                    src={project?.image || '/images/news/default_related.svg'}
                    alt={project?.title || 'project'}
                    width={172}
                    height={104}
                    sizes="(max-width: 1199px) 110px, 140px"
                />
            </div>
        </Link>
    );
};

export default LatestProjectComponent;
