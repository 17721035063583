// user 選單 - 任務能量
import Image from 'next/image';

const UserEnergy = ({ t, tasks, cmOrigin, currentEnergy, showExchangeModal }) => {
    return (
        <>
            {/* 點擊顯示說明彈窗 */}
            <p
                onClick={showExchangeModal}
                className="cp group text-bd3n text-black8 text-left flex items-center gap-x-[3px]"
            >
                <span className="opacity-[.6]">Energy</span>
                <Image
                    className="opacity-[.3] trans group-hover:opacity-[1] relative top-[1px]"
                    width={14}
                    height={14}
                    src="/images/icon/icon_question.svg"
                    alt="icon"
                />
            </p>
            <a
                href={cmOrigin + 'exchange/record'}
                target="_blank"
                rel="noreferrer noopener"
                className="between-center"
            >
                <span className="text-h5 text-black">{currentEnergy || 0}</span>
                {/* 會員系統 獲得紀錄 */}
                <p className="flex items-center text-bd3n text-black9 space-x-[10px]">
                    <span className="opacity-[.6]">{t('HISTORY')}</span>
                    <Image
                        width={8}
                        height={8}
                        src="/images/icon/icon_right_arrow.svg"
                        alt="more"
                    />
                </p>
            </a>
            {/* 每日任務累計 energy */}
            <p className="text-bd3b text-black mt-[16px] mb-[10px]">
                {t('DAILY_EARN')} {tasks?.gainedEnergy} Energy
            </p>
            {/* 進度條 */}
            <p className="rounded-[40px] w-full overflow-hidden flex justify-start h-[7px] bg-black2 bg-opacity-[.3]">
                <span
                    style={{
                        width: `calc(${tasks?.completedTask} / ${tasks?.totalTasks} * 100%)`,
                    }}
                    className="h-full bg-pale rounded-[40px]"
                />
            </p>
            {/* 當前進度 */}
            <p className="mt-[10px] text-bd3n text-black8 opacity-[.6] tabular-nums">
                {`${t('PROGRESS')}${tasks?.completedTask}/${tasks?.totalTasks}`}
            </p>
        </>
    );
};

export default UserEnergy;
