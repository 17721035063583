// 兌換任務相關
import apiClient from './apiClient';

class ExchangeClient {
    // 取得使用者資訊
    getUserState = () => apiClient('/energy/user/state');

    // 執行任務
    executeTask = (newsSlug) => apiClient(`/energy/task/check/5/${newsSlug}`);

    // 驗證任務
    verifyTasks = () => apiClient.post('/energy/task/retrieve-daily-reward');
}

const exchangeClient = new ExchangeClient();

export default exchangeClient;
