// 使用者選單 -- 任務列表
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { sify } from 'chinese-conv';

const Task = ({ task, t, locale }) => {
    return (
        <>
            <div>
                <p className="text-bd3b text-black">
                    {locale === 'tw' ? task?.name : sify(task?.name)}({task?.completed}/
                    {task?.requirement})
                </p>
                <p className="text-bd3n text-black8 opacity-[.6] tabular-nums">
                    {`${t('EARN')} ${task?.energyReward} Energy`}
                </p>
            </div>

            {/* 完成狀態 */}
            <span
                className={`text-btn2 flex-shrink-0 w-[62px] text-center text-white rounded-[4px] py-[8px] trans
                ${task?.isFinish ? 'bg-black3' : 'bg-pale group-hover:bg-pale8'}`}
            >
                {t(task?.isFinish ? 'FINISH' : 'TO')}
            </span>
        </>
    );
};

const ExchangeTasks = ({ tasks, t }) => {
    const params = useParams();
    const { locale } = params;

    return (
        <div className="border-b-1 border-dashed border-black3 pb-[20px] mb-[20px] space-y-[16px]">
            {tasks?.map((task, i) => {
                if (task?.isExternal)
                    return (
                        <a
                            key={i + 'task' + task?.id}
                            className="between-center gap-x-[10px] group"
                            href={task?.link}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <Task task={task} t={t} locale={locale} />
                        </a>
                    );

                return (
                    <Link
                        key={i + 'task' + task?.id}
                        href={task?.link}
                        className="between-center gap-x-[10px] group"
                    >
                        <Task task={task} t={t} locale={locale} />
                    </Link>
                );
            })}
        </div>
    );
};

export default ExchangeTasks;
