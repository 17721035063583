// 最新上線
import LoadMore from './LoadMore'; // 更多
import LatestNewsContent from './LatestNewsContent'; // tab + 新聞列表

const LatestNews = ({ newsList, t, loading, category }) => {
    return (
        <section className="pt-[50px] uxl:pt-[35px] blg:pt-[30px] umd:!pt-[18px]">
            {/* 新聞 tab 列表 */}
            <LatestNewsContent newsList={newsList} t={t} loading={loading} />

            {/* 更多 */}
            {category === 'Hot' ? (
                <LoadMore cl="news-load-more" path="/news" />
            ) : (
                <LoadMore cl="news-load-more" path={`/news?category=${category}`} />
            )}
        </section>
    );
};

export default LatestNews;
