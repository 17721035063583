'use client';
// 首頁 tab 切換 ＋ banner 輪播
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import newsClient from '@/common/apiClient/newsClient';

import LatestNewsTab from './LatestNewsTab'; // tab 按鈕
import BannerSwiper from './BannerSwiper'; // banner 輪播

import SelectedWrapper from './SelectedWrapper'; // 釘選新聞 3則
import LatestNews from './LatestNews'; // 最新上線 8則

// 需要 fetch 的分類
const newsArr = [
    'DeFi',
    'INTL',
    'View',
    'Social',
    'Supervise',
    'Business',
    'Tech',
    'AI',
    'Airdrop',
    'GameFi',
    'NFT',
    'Newbie',
];

const NewsTabContent = ({ locale, carouselNews, hotNews }) => {
    const { t } = useTranslation();
    const [category, setCategory] = useState('Hot'); // 新聞類別
    const [newsList, setNewsList] = useState({ Hot: hotNews }); // 新聞列表
    const [loading, setLoading] = useState(false);

    const selectedList =
        category === 'Hot' ? hotNews?.slice(0, 3) : newsList[category]?.slice(1, 4);

    const latestList =
        category === 'Hot' ? hotNews?.slice(3, 11) : newsList[category]?.slice(4, 13);

    // 取得各類別新聞列表
    // const getNewsListByCategory = async () => {
    //   setLoading(true);
    //   try {
    //     const { records } = await newsClient.getNewsListByCategory(
    //       locale,
    //       category
    //     );
    //     setNewsList((prev) => ({ ...prev, [category]: records }));

    //     setTimeout(() => {
    //       setLoading(false);
    //     }, 300);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };

    // 取得其他所有分類的新聞
    const fetchAllNews = async () => {
        try {
            const promises = newsArr.map(async (newsCategory) => {
                const { records } = await newsClient.getNewsListByCategory(locale, newsCategory);
                return { [newsCategory]: records };
            });

            const results = await Promise.all(promises);
            const newNewsList = results.reduce((acc, result) => ({ ...acc, ...result }), {});
            setNewsList((prev) => ({ ...prev, ...newNewsList }));
        } catch (e) {
            console.error(e);
        }
    };

    // 處理載入
    const handleLoading = () => {
        setLoading(!(category in newsList));
    };

    useEffect(() => {
        fetchAllNews();
    }, []);

    useEffect(() => {
        handleLoading();
    }, [category, newsList]);

    return (
        <>
            {/* tab 按鈕 */}
            <LatestNewsTab locale={locale} category={category} setCategory={setCategory} t={t} />

            {/* banner 輪播 */}
            <BannerSwiper
                carouselNews={carouselNews}
                newsList={newsList}
                category={category}
                loading={loading}
            />

            {/* 新聞列表  3則 */}
            <SelectedWrapper t={t} loading={loading} newsList={selectedList} />

            {/* 新聞列表  8則 */}
            <LatestNews t={t} loading={loading} category={category} newsList={latestList} />
        </>
    );
};

export default NewsTabContent;
