// 兌換任務條款彈窗
import { useState } from 'react';

const ExchangeRulesModal = ({ showExchangeModal, t, dispatch, setExchangeModal }) => {
    // 顯示條款
    const [showTerm, setShowTerm] = useState(false);

    // 關閉
    const onClose = () => {
        dispatch(setExchangeModal(false));
        setTimeout(() => {
            setShowTerm(false);
        }, 400);
    };

    return (
        <div className={`exchange-modal modal ${showExchangeModal ? 'act' : ''}`}>
            {/* 關閉用背景 */}
            <div className="close-bg" onClick={onClose} />

            <div
                className="relative z-[1] bg-white rounded-[4px] w-[610px] noScrollY max-w-[97vw] max-h-[97vh] p-[40px] usm:p-[30px] usx:px-[20px]
            [&>h6]:text-black [&>h6]:text-bd5b [&>h6]:text-center [&>h6]:mb-[20px]"
            >
                {!showTerm ? (
                    <>
                        <h6>{t('WHAT')}</h6>
                        <p
                            onClick={() => setShowTerm(true)}
                            className="cp text-bd3b text-pale mb-[20px] text-center trans hover:text-pale8"
                        >
                            {t('RULES')}
                        </p>
                        <pre className="text-black text-bd1m break-words whitespace-pre-wrap">
                            {t('CONTENT')}
                        </pre>
                    </>
                ) : (
                    <>
                        <h6>{t('TASK_POP_TITLE')}</h6>
                        <ul className="mt-[20px] text-bd1m text-black pl-[20px] list-outside list-disc">
                            {new Array(19).fill(0).map((_, i) => (
                                <li key={i + 'terms'}>{t(`RULE_${i}`)}</li>
                            ))}
                        </ul>
                    </>
                )}

                <button
                    onClick={onClose}
                    className="block mt-[20px] py-[10px] bg-black text-btn1 rounded-[40px] px-[22px] text-center mx-auto text-white"
                >
                    {t('CLOSE')}
                </button>
            </div>
        </div>
    );
};

export default ExchangeRulesModal;
